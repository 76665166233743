<template>
  <div class="home">

    <div class="page-title d-flex py-2 justify-content-between">

      <h1 class="h4 mb-0">Перечень заказов на поставку</h1>

      <div class="d-flex align-items-center">
        <BButton
          class="ml-3"
          variant="outline-primary"
          v-b-modal.import
        >Импортировать
        </BButton>
        <BButton
          v-if="isContractor"
          class="ml-3"
          variant="primary"
          to="/create-order"
        >Создать
        </BButton>
      </div>

    </div>

    <TableFilter
      :columns="fields"
      uniqueKey="table-1"
      @update-selected-columns="columns = $event"
      @search="filter = $event"
      @clear="clearFilterData"
      @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
    />

    <DefaultTable
      v-if="orders"
      :data="orders"
      :fields="fieldsFiltered"
      :filter="filter"
      :loading="loading"
      rowLink="/purchase-orders"
      emptyText="Нет заказов"
      :noLocalSorting="true"
      @sort-change="sorting"
      @pagination-change="paginationChange"
      @per-page-change="perPageChange"
    />

    <FilterSidebar
      id="filter"
      v-model="filterData"
      @apply="updateDataWithFilters(true)"
    />

    <b-modal
      id="import"
      centered
      title="Импорт заказа"
    >

      <div class="p-2 bg-light">

        <div class="font-weight-bold text-muted">Файл для импорта <span class="text-danger">*</span></div>

        <b-form-file
          v-model="importFile"
          class="mt-1"
          plain
        />

      </div>

      <template #modal-footer="{ hide }">
        <b-button
          variant="outline-primary"
          @click="hide()"
        >
          Отмена
        </b-button>
        <b-button
          variant="primary"
          @click="sendImport"
        >
          Импортировать
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="import-success"
      centered
      title="Импорт завершён"
    >

      <div class="p-2 bg-light">

        <p>Импортирование файла «{{ importFile ? importFile.name : '' }}» завершено</p>

      </div>

      <template #modal-footer="{ hide }">
        <b-button
          variant="primary"
          @click="hide()"
        >
          Хорошо
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DefaultTable from "@/components/Tables/Default";
import TableFilter from "@/components/TableFilter";
import FilterSidebar from "@/components/FilterSidebar";

function initialFilterData() {
  return {
    number: "",
    order_date: ["", ""],
    deadline_date: ["", ""],
    customer_status: "",
    provider_status: "",
    provider_contract_id: "",
    work_agreement_id: "",
    organization_id: "",
    work_type: "",
    object_id: "",
    sub_object_id: "",
    amount_without_vat: "",
  };
}

export default {
  name: "PurchaseOrders",
  components: { FilterSidebar, DefaultTable, TableFilter },
  data: () => ({
    fields: [
      {
        key: "state",
        sortable: true,
        label: "С",
        full: "Состояние",
        openDefault: true,
      },
      {
        key: "number",
        sortable: true,
        label: "№ заказа",
        full: "Номер заказа",
        openDefault: true,
      },
      {
        key: "order_date",
        sortable: true,
        label: "Дата заказа",
        full: "Дата заказа",
        openDefault: true,
      },
      {
        key: "deadline_date",
        sortable: true,
        label: "Срок исполнения заявки",
        full: "Срок исполнения заявки",
        openDefault: true,
      },
      {
        key: "work_type",
        sortable: true,
        label: "Вид работ",
        full: "Вид работ",
        openDefault: true,
      },
      {
        key: "organization",
        sortable: true,
        label: "Филиал",
        full: "Филиал",
        openDefault: true,
      },
      {
        key: "contractor",
        sortable: true,
        label: "Подрядчик",
        full: "Подрядчик",
      },
      {
        key: "work_agreement_number",
        sortable: true,
        label: "№ договора подряда",
        full: "Номер договора подряда",
      },
      {
        key: "work_agreement_date",
        sortable: true,
        label: "Дата договора подряда",
        full: "Дата договора подряда",
      },
      {
        key: "positions_sum_amount_without_vat",
        sortable: true,
        label: "Сумма",
        full: "Сумма",
        openDefault: true,
      },
      {
        key: "customer_status",
        sortable: true,
        label: "Статус согласования с заказчиком",
        full: "Статус согласования с заказчиком",
        openDefault: true,
      },
      {
        key: "provider_status",
        sortable: true,
        label: "Статус согласования с поставщиком",
        full: "Статус согласования с поставщиком",
        openDefault: true,
      },
      {
        key: "provider",
        sortable: true,
        label: "Поставщик",
        full: "Поставщик",
        openDefault: true,
      },
      {
        key: "provider_contract_number",
        sortable: true,
        label: "№ договора поставки",
        full: "Номер договора поставки",
        openDefault: true,
      },
      {
        key: "provider_contract_date",
        sortable: true,
        label: "Дата договора поставки",
        full: "Дата договора поставки",
        openDefault: true,
      },
      {
        key: "object",
        sortable: true,
        label: "Объект",
        full: "Объект",
        openDefault: true,
      },
      {
        key: "subobject",
        sortable: true,
        label: "Подобъект",
        full: "Подобъект",
      },
      {
        key: "work_start_date",
        sortable: true,
        label: "Начало работ",
        full: "Начало работ",
      },
      {
        key: "work_end_date",
        sortable: true,
        label: "Окончание работ",
        full: "Окончание работ",
      },
      {
        key: "contractor_responsible_full_name",
        sortable: true,
        label: "Ответственный на объекте",
        full: "Ответственный на объекте",
      },
      {
        key: "contractor_responsible_phone",
        sortable: true,
        label: "Телефон ответственного",
        full: "Телефон ответственного",
      },
      {
        key: "comments",
        sortable: true,
        label: "Комм",
        full: "Комментарии",
      },
    ],
    filterData: initialFilterData(),
    columns: [],
    filter: "",
    loading: false,
    importFile: null,
    breadcrumbs: [
      { title: "Главная", link: "/" },
      { title: "Заказ ПО", link: "/" },
      { title: "Перечень заказов на поставку" },
    ],
    sortingData: {
      sort_field: "",
      sort_order: "",
      page: "",
      per_page: 25,
    },
  }),

  computed: {
    ...mapState({
      orders: (state) => state.orders.orders,
      providersContracts: (state) => state.referenceBooks.providersContracts,
    }),
    ...mapGetters({
      customerStatusesOptions: "getCustomerStatusesOptions",
      providerStatusesOptions: "getProviderStatusesOptions",
      contrAgentsOptions: "getContrAgentsOptions",
      providersContractsOptions: "getProvidersContractsOptions",
      workAgreementsOptions: "getWorkAgreementsOptions",
      organizationsOptions: "getOrganizationsOptions",
      isContractor: "isContractor",
    }),
    fieldsFiltered() {
      let res = [];
      this.fields.forEach((item) => {
        if (this.columns.filter((col) => col === item.label).length) {
          res.push(item);
        }
      });
      return res.length > 0 ? res : this.fields;
    },
  },

  methods: {
    async getData() {
      this.loading = true;
      await this.$store.dispatch("getOrders");
      this.loading = false;
    },
    sendImport() {
      if (this.importFile !== null) {
        this.$bvModal.show("import-success");
        this.$bvModal.hide("import");
      } else {
        this.$bvToast.toast("Вам необходимо загрузить файл формата XML", {
          title: "Вы не прикрепили файл для импорта",
          toaster: "b-toaster-top-right",
          solid: true,
          appendToast: false,
          variant: "danger",
        });
      }
    },
    clearFilterData() {
      this.filterData = initialFilterData();
      this.getData();
    },
    async updateDataWithFilters(clearSort) {
      this.loading = true;
      if (clearSort) {
        this.sortingData.sort_field = "";
        this.sortingData.sort_order = "";
        this.sortingData.page = "";
      }
      await this.$store.dispatch("getOrders", {
        ...this.filterData,
        ...this.sortingData,
      });
      this.loading = false;
    },
    sorting(event) {
      this.sortingData.sort_field = event.sortBy;
      this.sortingData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updateDataWithFilters();
    },
    paginationChange(page) {
      this.sortingData.page = page;
      this.updateDataWithFilters();
    },
    perPageChange(perPage) {
      this.sortingData.page = "";
      this.sortingData.per_page = perPage;
      this.updateDataWithFilters();
    },
  },
  mounted() {
    this.getData();
    this.fields.forEach((item) => {
      if (item.openDefault) {
        this.columns.push(item.label);
      }
    });
  },
};
</script>
